import React, { useEffect, useState } from 'react';
import { Menu, Button, Typography } from 'antd';
import { BackIcon, DashboardIcon, SettingItemIcon } from '../config/icon';
import themes from '../theme';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
const { SubMenu } = Menu;
const { colors } = themes;
const { Title } = Typography;

function getActiveKey(pathname){
  switch (pathname) {
    case '/settings/organisation/profile':
      return 'organisation';
      break;
    case '/settings/users':
      return 'users-roles';
      break;
    case '/settings/preferences':
      return 'preferences';
      break;
    case '/settings/preferences':
      return 'preferences';
      break;
    default:
      break;
  }
}

const SettingsMenueItems = ({ toggleCollapsed }) => {
  const { pathname } = useLocation();
  const history = useNavigate();

  return (
    <>
      <Button type='text' onClick={()=> history('dashboard')} className='back-button' icon={<BackIcon color={colors.white} />}>Back</Button>
      <Title className='menu-title'>Settings</Title>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[getActiveKey(pathname)]}
        // openKeys={['hr']}
        // defaultSelectedKeys={[activeKey]}
        style={{ height: '100%', borderRight: 0 }}
      >
        <Menu.Item key="organisation" className='pl10' icon={<SettingItemIcon color={colors.white} />}>
          <NavLink to={`/settings/organisation/profile`}>Organisation Profile</NavLink>
        </Menu.Item>
        <Menu.Item key="users-roles" className='pl10' icon={<SettingItemIcon color={colors.white} />}>
          <NavLink to={`/settings/users`}>Users & Roles</NavLink>
        </Menu.Item>
        {/* <Menu.Item key="branches" className='pl10' icon={<SettingItemIcon color={colors.white} />}>
          <NavLink to={`/settings/branches`}>Branches</NavLink>
        </Menu.Item> */}
        <Menu.Item key="preferences" className='pl10' icon={<SettingItemIcon color={colors.white} />}>
          <NavLink to={`/settings/preferences`}>Preferences</NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default SettingsMenueItems;
