
const config = {
    filterList: [
        {
            key:'All',
            value:1,
            lable:'All Employees'
        },
        {
            key:'Active',
            value:2,
            lable:'Active Employees'
        },
        {
            key:'Incomplete',
            value:3,
            lable:'Incomplete Employees'
        },
        {
            key:'PortalEnabled',
            value:4,
            lable:'Portal Enabled Employees'
        },
        {
            key:'PortalDisabled',
            value:5,
            lable:'Portal Disabled Employees'
        },
        {
            key:'Terminated',
            value:6,
            lable:'Terminated Employess'
        }
    ],
    columns:[
        {
            key:'name',
            lable:'Name'
        },
        {
            key:'designation',
            lable:'Designation'
        },
        {
            key:'company',
            lable:'Company'
        },
        {
            key:'email',
            lable:'Email'
        },
        {
            key:'phone',
            lable:'Phone'
        },
        {
            key:'pipeline stage',
            lable:'pipeline stage'
        }
    ]
};
  
  export default config;