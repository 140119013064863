import Styled from 'styled-components';
import themes from '../theme';
import config from '../config/config';
import { Layout } from 'antd';
const { colors, mediaQueries } = themes;
const { header, leftSideBar, leftSideBarClose } = config;

const AuthMain = Styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: stretch;
`
const AuthLeft = Styled.div`
    width: 50%;
    background: ${colors.backgroundAlt};
    height: 100%;
    justify-content: center;
    align-items: center;
    display:none;
    .auth-side-content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    ${mediaQueries.md}{
        display: flex;
    }
`
const AuthRight = Styled.div`
    width: 50%;
    display: flex;
    background: ${colors.white};
    height: 100%;
    overflow:auto;
    justify-content: center;
    align-items: center;
`
const TextContent = Styled.div`
    display: flex;
    width: 100%;
    max-width: 410px;
    text-align:left;
    flex-direction: column;
    .title{
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color:${colors.textAlt};
    }
    .text{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color:${colors.textAlt};
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
        img{
            margin-right:14px;
        }
    }
`

const Div = Styled.div`
    position: relative;
    height:100%;
    width:100%;
    .ant-layout{
        height:100%;
        width:100%;
        box-sizing: border-box;
    }
    .formLable{
        display:block;
        margin-bottom:10px;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: ${colors.dark};
        span{
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 17px;
            letter-spacing: 0.02em;
            color: ${colors.gray1};
        }

    }
    .ant-select-selection-placeholder{
        color: ${colors.placeholder};
    }
    input, .ant-picker-input > input{
        &:focus{
            -webkit-box-shadow: 0 0 0px 1000px white inset !important;
            outline: 0 !important;
        }
        &::-webkit-input-placeholder { /* Edge */
            color: ${colors.placeholder};
          }
          
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: ${colors.placeholder};
          }
          
        &::placeholder {
            color: ${colors.placeholder};
        }
    }
`

const LeftSidebar = Styled.div`
    height: 100%;
    position: fixed;
    .back-button{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: ${colors.white};
        display: flex;
        width: 100%;
        padding: 12px;
        svg{
            margin-right:10px;
        }
    }
    .menu-title{
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.04em;
        color: #E9E9E9;
        padding: 20px;
        margin: 0;
    }
    .ant-layout-sider{
        height:100%;
        padding-top: 30px;
        background:${colors.sidebarBackground};
        .ant-menu{
            background:${colors.sidebarBackground};
            padding:4px;
            .ant-menu{
                padding:0px;
                li{
                    margin-top:0;
                    margin-bottom:0;
                }
            }
            &.ant-menu-sub{
                background:${colors.sidebarSubBackground};
                .ant-menu-item{
                    padding-left: 20px !important;
                }
            }
            & > li{
                padding:0px 0px;
                .ant-menu-title-content{
                    color:${colors.white};
                    a{
                        color:${colors.white};
                    }
                }
            }
            .ant-menu-item{
                height:34px;
                padding-left: 10px !important;
                &.pl10{
                    padding-left: 16px !important;
                }
                span{
                    height: 100%;
                    display: flex;
                    width: 100%;
                    align-items: center;
                    padding-left: 16px;
                }
                &.ant-menu-item-selected{
                    background:${colors.primary};
                }
            }
            .ant-menu-submenu{
                .ant-menu-submenu-title{
                    padding-left: 10px !important;
                    span{
                        height: 100%;
                        display: flex;
                        width: 100%;
                        align-items: center;
                        padding-left: 16px;
                    }
                }
            }
        }
        &.ant-layout-sider-collapsed{
            .ant-menu-item{
                text-align:center;
                padding-left: 0px !important;
            }
            .ant-menu-submenu{
                .ant-menu-submenu-title{
                    text-align:center;
                    padding-left: 12px !important;
                }
            }
        }
    }
`

const RightContent = Styled(Layout)`
    padding-left: ${({ collapsed }) => (collapsed === true || collapsed === 'true' ? leftSideBarClose : leftSideBar )}px;
    background: ${colors.white}
`

const HeaderContainer = Styled.div`
    .header{
        height: ${header}px;
        padding: 0 0px;
        display: flex;
        position: fixed;
        width: 100%;
        background: ${colors.hrader};
        justify-content: space-between;
        align-items: center;   
        z-index: 1000;     
    }

`

const Logo = Styled.div`
    display: flex;
    background: #fff;
    justify-content: center;
    width: 100%;
    max-width: 170px;
    display: flex;
    background: ${colors.sidebarBackground};
    height: 100%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    max-width: 245px;
    padding: 0px 10px;
    img{
        width: 120px;
    }
`

const HeaderLeft = Styled.div`
    display: flex;
    width: 100%;
    padding: 0px 10px;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
`

const HeaderRight = Styled.div` 
    display: flex;
    width: 100%;
    padding: 0px 10px;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
`

const AddButton = Styled.div`
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const MenuToggle = Styled.div`
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const SearchBar = Styled.div`
    margin: 0 10px;
    background: #fff;
    border-radius: 50px;
    padding: 0 10px;
    height: 35px;
    .ant-input-group{
        display: flex;
        margin-right: 5px;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        .search-icon{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 30px;
            margin-right: 5px;
        }
        .ant-input{
            width: 100%;
            height: 100%;
            border: 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0.02em;
            text-align: left;
            color:${colors.dark};
            &:focus, .ant-input-focused{
                box-shadow: none !important;
            }
        }
    }
`

const Setting = Styled.div`
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    a{
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }
`

const AuthInfo = Styled.div`
`

export { AuthMain, AuthLeft, AuthRight, MenuToggle, TextContent, Div, HeaderContainer, Logo, HeaderLeft, LeftSidebar, RightContent, AddButton, SearchBar, HeaderRight, Setting, AuthInfo };
