import React from "react";

const Icon = (props) => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 9H3" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17 4H3" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17 4H3" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17 14H3" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default Icon;
