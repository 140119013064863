import React from "react";

const Icon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_626_122424)">
      <path d="M19.166 10L4.16601 10" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.5 5.83398L3.33333 10.0007L7.5 14.1673" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_626_122424">
        <rect width="20" height="20" fill={props.color} transform="matrix(-1 0 0 1 20 0)"/>
      </clipPath>
      </defs>
    </svg>

  );
};

export default Icon;
