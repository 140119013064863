import React from 'react';
import { Menu } from 'antd';
import { DashboardIcon } from '../config/icon';
import themes from '../theme';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkPermission } from '../utility/utility';
const { SubMenu } = Menu;
const { colors } = themes;


function getActiveKey(pathname){
  console.log('pathname', pathname);
  switch (pathname) {
    case '/learners':
      return ['learners'];
      break;
    case '/instructors':
      return ['instructors'];
      break;
    case '/venue-provider':
      return ['venue-provider'];
      break;
    case '/venues':
      return ['venues'];
      break;
    case '/courses':
      return ['courses'];
      break;
    case '/batches':
      return ['batches'];
      break;
    case '/faqs':
      return ['faqs'];
      break;
    case '/supports':
      return ['supports'];
      break;
    default:
      return ['dashboard'];
      break;
  }
}


function getOpenKey(pathname){
  console.log('pathname', pathname);
  if(pathname.includes('learners')){
    return ['learners'];
  }else if(pathname.includes('instructors')){
    return ['instructors'];
  }else if(pathname.includes('venue-provider')){
    return ['venue-provider'];
  }else if(pathname.includes('venues')){
    return ['venues'];
  }else if(pathname.includes('courses')){
    return ['courses'];
  }else if(pathname.includes('batches')){
    return ['batches'];
  }else if(pathname.includes('faqs')){
    return ['faqs'];
  }else if(pathname.includes('supports')){
    return ['supports'];
  }else{
    return ['dashboard'];
  }
}

const MenuItems = ({ toggleCollapsed }) => {
  // const { pathname, pattern } = useMatch();
  const { permissions, modules } = useSelector(state => {
    return {
      permissions: state.permissions.data,
      modules: state.modules.data,
    };
  });
  const [openKeys, setOpenKeys] = React.useState([]);
  const { pathname } = useLocation();
  const rootSubmenuKeys = modules.map(el=> el.name);
  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={getActiveKey(pathname)}
      onOpenChange={onOpenChange}
      openKeys={[...openKeys,...getOpenKey(pathname)]}
      style={{ height: '100%', borderRight: 0 }}
    >
      <Menu.Item key="dashboard" icon={<DashboardIcon color={colors.white} />}>
        <NavLink to={`/dashboard`}>Dashboard</NavLink>
      </Menu.Item>
      <SubMenu key="managelearners" icon={<DashboardIcon color={colors.white} />} title="Manage Learners">
        <Menu.Item key="learners"><NavLink to={`/learners`}>learners</NavLink></Menu.Item>
        <Menu.Item key="reviews"><NavLink to={`/reviews`}>Reviews</NavLink></Menu.Item>
      </SubMenu>
      <SubMenu key="managecourses" icon={<DashboardIcon color={colors.white} />} title="Manage Courses">
        <Menu.Item key="instructors"><NavLink to={`/instructors`}>Instructors</NavLink></Menu.Item>
        <Menu.Item key="courses"><NavLink to={`/courses`}>Courses</NavLink></Menu.Item>
        <Menu.Item key="batches"><NavLink to={`/batches`}>Batches</NavLink></Menu.Item>
      </SubMenu>
      <SubMenu key="managevenues" icon={<DashboardIcon color={colors.white} />} title="Manage Venues">
        <Menu.Item key="venue-provider"><NavLink to={`/venue-provider`}>Venue Providers</NavLink></Menu.Item>
        <Menu.Item key="venues"><NavLink to={`/venues`}>Venues</NavLink></Menu.Item>
        <Menu.Item key="bookings"><NavLink to={`/bookings`}>Bookings</NavLink></Menu.Item>
      </SubMenu>
      <SubMenu key="support" icon={<DashboardIcon color={colors.white} />} title="Support">
        <Menu.Item key="tickets"><NavLink to={`/tickets`}>Tickets</NavLink></Menu.Item>
        <Menu.Item key="faqs"><NavLink to={`/faqs`}>FAQ</NavLink></Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default MenuItems;
