import React, { useState, useEffect, useRef } from 'react';
import { Layout, Menu, Dropdown, Input, Avatar, Typography, Button } from 'antd';
import { HeaderLeft, MenuToggle, HeaderRight, Logo, AddButton, SearchBar, Setting, AuthInfo, HeaderContainer } from './style';
import { AddCircleIcon, MenuIcon, PopDwonIcon, SearchIcon, SettingIcon, LogoutIcon } from '../config/icon';
import themes from '../theme';
import { NavLink, useNavigate } from 'react-router-dom';
import config from '../config/config';
import { useSelector, useDispatch } from 'react-redux';
import { checkIsAdmin, checkIsSupperAdmin, getImageUrl } from '../utility/utility';
import { logOut } from '../redux/authentication/actionCreator';

const { organisations } = config;
const { Text } = Typography;
const { Header } = Layout;
const { colors } = themes;
const TopMenu = ({ toggleCollapsed }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { userData } = useSelector(state => {
    return {
      userData: state.auth.data
    };
  });

  const [imageUrl, setImageUrl] = useState(()=>{
    if(userData && userData.image){
      return getImageUrl(userData.image)
    }
  });

  const [rightMenu, setRightMenu] = useState(false);


  const handlelogout = async (values) => {
    let reponse = await dispatch(logOut());
    window.location.reload();
    history('/', { replace: false });
    // history('/dashboard', { replace: false });
  };


  return (
    <HeaderContainer>
      <Header className="header">
        <Logo style={{display: 'flex',background: '#fff',justifyContent: 'center'}}>
          <NavLink to={'/'}>
            <img src={require('../assets/img/logo.png')} alt="" />
          </NavLink>
         
          {/* <MenuToggle onClick={toggleCollapsed}>
            <MenuIcon color={colors.black} />
          </MenuToggle> */}
        </Logo>
        <HeaderLeft>
          {/* <AddButton>
            <Dropdown className="search-icon" overlay={AddOption} trigger={['click']}>
              <Text type="text" style={{ display: 'flex' }} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <AddCircleIcon color={colors.white} />
              </Text>
            </Dropdown>
          </AddButton> */}
          <SearchBar>
            <Input.Group compact>
              <Text type="text" className="ant-dropdown-link">
                <SearchIcon color={colors.gray2} />
              </Text>
              <Input style={{width:300}} placeholder={'Search'} />
            </Input.Group>
          </SearchBar>
        </HeaderLeft>
        <HeaderRight>
          
          <AuthInfo>
            {imageUrl?<Avatar size={40} src={imageUrl} />:<Avatar size={40} src="https://joeschmoe.io/api/v1/random" />}
          </AuthInfo>
          {
            checkIsAdmin(userData && userData.userRoles?userData.userRoles:[])?
              <Setting>
                <Button onClick={()=> history('/global/setting') } type='link' icon={<SettingIcon color={colors.black} />} />
              </Setting>
            :null
          }
        <Setting>
           <Button onClick={()=> handlelogout() } type='link' icon={<LogoutIcon color={colors.black} />} />
        </Setting>
        </HeaderRight>
      </Header>
    </HeaderContainer>
  );
};

export default TopMenu;
