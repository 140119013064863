const actions = {
 
  USER_BEGIN: 'USER_BEGIN',
  USER_SUCCESS: 'USER_SUCCESS',
  USER_ERR: 'USER_ERR',

  ADD_USER_BEGIN: 'ADD_USER_BEGIN',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_ERR: 'ADD_USER_ERR',


  userBegin: () => {
    return {
      type: actions.USER_BEGIN,
    };
  },

  userSuccess: data => {
    return {
      type: actions.USER_SUCCESS,
      data,
    };
  },

  userErr: err => {
    return {
      type: actions.USER_ERR,
      err,
    };
  },

  addUserBegin: () => {
    return {
      type: actions.ADD_USER_BEGIN,
    };
  },

  addUserSuccess: data => {
    return {
      type: actions.ADD_USER_SUCCESS,
      data,
    };
  },

  addUserErr: err => {
    return {
      type: actions.ADD_USER_ERR,
      err,
    };
  },
};

export default actions;
