import Cookies from 'js-cookie';
import actions from './actions';

const { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_ERR, LOGIN_WITH_PHONE_BEGIN, LOGIN_WITH_PHONE_SUCCESS, LOGIN_WITH_PHONE_ERR, LOGIN_WITH_PHONE_VERFIY_BEGIN, LOGIN_WITH_PHONE_VERFIY_SUCCESS, LOGIN_WITH_PHONE_VERFIY_ERR, FORGOT_PASSWORD_BEGIN, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERR, RESENT_BEGIN, RESENT_SUCCESS, RESENT_ERR, LOGOUT_BEGIN, LOGOUT_SUCCESS, LOGOUT_ERR, INVITE_CHECK_BEGIN, INVITE_CHECK_SUCCESS, INVITE_CHECK_ERR } = actions;
// console.log('Cookies.get(techlogedIn)', Cookies.get('techlogedIn'));
const data = localStorage.getItem('simplitrainAdmin');
const initState = {
  login: Cookies.get('simplitrainlogedIn') && data,
  data: data?JSON.parse(window.atob(data)):null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGIN_WITH_PHONE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_WITH_PHONE_SUCCESS:
      return {
        ...state,
        data: data,
        loading: false,
      };
    case LOGIN_WITH_PHONE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGIN_WITH_PHONE_VERFIY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_WITH_PHONE_VERFIY_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_WITH_PHONE_VERFIY_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case FORGOT_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgot: data,
        loading: false,
      };
    case FORGOT_PASSWORD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case INVITE_CHECK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case INVITE_CHECK_SUCCESS:
      return {
        ...state,
        forgot: data,
        loading: false,
      };
    case INVITE_CHECK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case RESENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESENT_SUCCESS:
      return {
        ...state,
        resent: data,
        loading: false,
      };
    case RESENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default AuthReducer;
