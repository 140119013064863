import base from "./base";
import { themeColors } from "./colors";

const themes = {
    ...base,
    colors: {...themeColors},
};


export default themes;
