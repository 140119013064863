import { theme, darkTheme } from './theme/themeVariables';
import employees from './jsonData/employees';
import companies from './jsonData/companies';
import leads from './jsonData/leads';
import instructors from './jsonData/instructor';
import learners from './jsonData/learners';
import batches from './jsonData/batches';
import bookings from './jsonData/bookings';
import courses from './jsonData/courses';
import faqs from './jsonData/faqs';
import reviews from './jsonData/reviews';
import tickets from './jsonData/tickets';
import venueProvider from './jsonData/venueProvider';
import venues from './jsonData/venues';
import users from './jsonData/users';
import organisations from './jsonData/organisations';
import common from './jsonData/common';

const storageKeys =  {techModules:'simplitrainModules', activeOrganisation:'activeOrganisation', techUser:'simplitrainAdmin', access_token:'access_token', techlogedIn:'simplitrainlogedIn'};

const config = {
  darkMode: false,
  topMenu: false,
  rtl: false,
  header:50,
  leftSideBar:245,
  leftSideBarClose:50,
  theme,
  imagePath: process.env.REACT_APP_IMG_URL,
  APPNAME: process.env.REACT_APP_APPNAME || 'Simplitrain',
  darkTheme,
  storageKeys: storageKeys,
  storageTypes:{
    localStorage:[storageKeys.techModules, storageKeys.activeOrganisation, storageKeys.techUser, storageKeys.access_token],
    cookies:[storageKeys.techlogedIn],
  },
  employees,
  companies,
  bookings,
  reviews,
  tickets,
  venues,
  venueProvider,
  courses,
  faqs,
  batches,
  leads,
  instructors,
  learners,
  users,
  organisations,
  common,
};

export default config;
