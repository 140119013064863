const actions = {
 
  ROLE_BEGIN: 'ROLE_BEGIN',
  ROLE_SUCCESS: 'ROLE_SUCCESS',
  ROLE_ERR: 'ROLE_ERR',

  ADD_ROLE_BEGIN: 'ADD_ROLE_BEGIN',
  ADD_ROLE_SUCCESS: 'ADD_ROLE_SUCCESS',
  ADD_ROLE_ERR: 'ADD_ROLE_ERR',

  REMOVE_ROLE_BEGIN: 'REMOVE_ROLE_BEGIN',
  REMOVE_ROLE_SUCCESS: 'REMOVE_ROLE_SUCCESS',
  REMOVE_ROLE_ERR: 'REMOVE_ROLE_ERR',

  ROLE_PERMISSION_BEGIN: 'ROLE_PERMISSION_BEGIN',
  ROLE_PERMISSION_SUCCESS: 'ROLE_PERMISSION_SUCCESS',
  ROLE_PERMISSION_ERR: 'ROLE_PERMISSION_ERR',

  roleBegin: () => {
    return {
      type: actions.ROLE_BEGIN,
    };
  },

  roleSuccess: data => {
    return {
      type: actions.ROLE_SUCCESS,
      data,
    };
  },

  roleErr: err => {
    return {
      type: actions.ROLE_ERR,
      err,
    };
  },

  addRoleBegin: () => {
    return {
      type: actions.ADD_ROLE_BEGIN,
    };
  },

  addRoleSuccess: data => {
    return {
      type: actions.ADD_ROLE_SUCCESS,
      data,
    };
  },

  addRoleErr: err => {
    return {
      type: actions.ADD_ROLE_ERR,
      err,
    };
  },
  
  rolePermissionBegin: () => {
    return {
      type: actions.ROLE_PERMISSION_BEGIN,
    };
  },

  rolePermissionSuccess: data => {
    return {
      type: actions.ROLE_PERMISSION_SUCCESS,
      data,
    };
  },

  rolePermissionErr: err => {
    return {
      type: actions.ROLE_PERMISSION_ERR,
      err,
    };
  },

  removeRoleBegin: () => {
    return {
      type: actions.REMOVE_ROLE_BEGIN,
    };
  },

  removeRoleSuccess: data => {
    return {
      type: actions.REMOVE_ROLE_SUCCESS,
      data,
    };
  },

  removeRoleErr: err => {
    return {
      type: actions.REMOVE_ROLE_ERR,
      err,
    };
  },
};

export default actions;
