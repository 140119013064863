
const config = {
    importExportList: [
        {
            key:'Import',
            value:1,
            lable:'Import'
        },
        {
            key:'Export',
            value:2,
            lable:'Export'
        }
    ],
    roles:{MASTERADMIN:'MasterAdmin',SUPPERADMIN:'superadmin',ADMIN:"ADMIN"}
};
  
  export default config;