import actions from './actions';
// import { message } from 'antd';

const { redirectionBegin, redirectionSuccess, redirectionErr } = actions;

const redirection = (data) => {
  return async dispatch => {
    try {
      console.log('data', data);
      dispatch(redirectionBegin());
      // return <Navigate to={data} />;
      return dispatch(redirectionSuccess(data));
    } catch (err) {
      dispatch(redirectionErr(err));
    }
  };
};

export { redirection };
