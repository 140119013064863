import config from '../../config/config';
import actions from './actions';

const {
  ROLE_BEGIN,
  ROLE_SUCCESS,
  ROLE_ERR,

  ADD_ROLE_BEGIN,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_ERR,

  REMOVE_ROLE_BEGIN,
  REMOVE_ROLE_SUCCESS,
  REMOVE_ROLE_ERR,

  ROLE_PERMISSION_BEGIN,
  ROLE_PERMISSION_SUCCESS,
  ROLE_PERMISSION_ERR,

} = actions;

const initialStateList = {
  data: [],
  loading: false,
  error: null,
};

const RoleReducer = (state = initialStateList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ROLE_BEGIN:
      return {
        ...initialStateList,
        loading: true,
      };
    case ROLE_SUCCESS:
      return {
        ...initialStateList,
        data,
        loading: false,
      };
    case ROLE_ERR:
      return {
        ...initialStateList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const initialStateObj = {
  data: {},
  loading: false,
  error: null,
};

const addRoleReducer = (state = initialStateObj, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ADD_ROLE_BEGIN:
      return {
        ...initialStateObj,
        loading: true,
      };
    case ADD_ROLE_SUCCESS:
      return {
        ...initialStateObj,
        data,
        loading: false,
      };
    case ADD_ROLE_ERR:
      return {
        ...initialStateObj,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
// ROLE_PERMISSION_BEGIN
const initialPermissionObj = {
  data: [],
  loading: false,
  error: null,
};

const addPermissionReducer = (state = initialPermissionObj, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ROLE_PERMISSION_BEGIN:
      return {
        ...initialStateObj,
        loading: true,
      };
    case ROLE_PERMISSION_SUCCESS:
      return {
        ...initialStateObj,
        data,
        loading: false,
      };
    case ROLE_PERMISSION_ERR:
      return {
        ...initialStateObj,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const removeRoleReducer = (state = initialStateObj, action) => {
  const { type, data, err } = action;
  switch (type) {
    case REMOVE_ROLE_BEGIN:
      return {
        ...initialStateObj,
        loading: true,
      };
    case REMOVE_ROLE_SUCCESS:
      return {
        ...initialStateObj,
        data,
        loading: false,
      };
    case REMOVE_ROLE_ERR:
      return {
        ...initialStateObj,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};


export { RoleReducer, addRoleReducer, addPermissionReducer, removeRoleReducer };
