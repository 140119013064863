const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  LOGIN_WITH_PHONE_BEGIN: 'LOGIN_WITH_PHONE_BEGIN',
  LOGIN_WITH_PHONE_SUCCESS: 'LOGIN_WITH_PHONE_SUCCESS',
  LOGIN_WITH_PHONE_ERR: 'LOGIN_WITH_PHONE_ERR',

  LOGIN_WITH_PHONE_VERFIY_BEGIN: 'LOGIN_WITH_PHONE_VERFIY_BEGIN',
  LOGIN_WITH_PHONE_VERFIY_SUCCESS: 'LOGIN_WITH_PHONE_VERFIY_SUCCESS',
  LOGIN_WITH_PHONE_VERFIY_ERR: 'LOGIN_WITH_PHONE_VERFIY_ERR',

  FORGOT_PASSWORD_BEGIN: 'FORGOT_PASSWORD_BEGIN',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERR: 'FORGOT_PASSWORD_ERR',

  RESENT_BEGIN: 'RESENT_BEGIN',
  RESENT_SUCCESS: 'RESENT_SUCCESS',
  RESENT_ERR: 'RESENT_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  INVITE_CHECK_BEGIN: 'INVITE_CHECK_BEGIN',
  INVITE_CHECK_SUCCESS: 'INVITE_CHECK_SUCCESS',
  INVITE_CHECK_ERR: 'INVITE_CHECK_ERR',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: data => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: err => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  loginWihPhoneBegin: () => {
    return {
      type: actions.LOGIN_WITH_PHONE_BEGIN,
    };
  },

  loginWihPhoneSuccess: data => {
    return {
      type: actions.LOGIN_WITH_PHONE_SUCCESS,
      data,
    };
  },

  loginWihPhoneErr: err => {
    return {
      type: actions.LOGIN_WITH_PHONE_ERR,
      err,
    };
  },
  
  loginWihPhoneVerifyBegin: () => {
    return {
      type: actions.LOGIN_WITH_PHONE_VERFIY_BEGIN,
    };
  },

  loginWihPhoneVerifySuccess: data => {
    return {
      type: actions.LOGIN_WITH_PHONE_VERFIY_SUCCESS,
      data,
    };
  },

  loginWihPhoneVerifyErr: err => {
    return {
      type: actions.LOGIN_WITH_PHONE_VERFIY_ERR,
      err,
    };
  },

  forgotPasswordBegin: () => {
    return {
      type: actions.FORGOT_PASSWORD_BEGIN,
    };
  },

  forgotPasswordSuccess: data => {
    return {
      type: actions.FORGOT_PASSWORD_SUCCESS,
      data,
    };
  },

  forgotPasswordErr: err => {
    return {
      type: actions.FORGOT_PASSWORD_ERR,
      err,
    };
  },

  inviteCheckBegin: () => {
    return {
      type: actions.INVITE_CHECK_BEGIN,
    };
  },

  inviteCheckSuccess: data => {
    return {
      type: actions.INVITE_CHECK_SUCCESS,
      data,
    };
  },

  inviteCheckErr: err => {
    return {
      type: actions.INVITE_CHECK_ERR,
      err,
    };
  },

  resentBegin: () => {
    return {
      type: actions.RESENT_BEGIN,
    };
  },

  resentSuccess: data => {
    return {
      type: actions.RESENT_SUCCESS,
      data,
    };
  },

  resentErr: err => {
    return {
      type: actions.RESENT_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: data => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: err => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },
};

export default actions;
