import React from "react";

const Icon = (props) => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9304 4.81641L11.5194 13.8597C11.4988 14.3124 11.0795 14.6794 10.5856 14.6794H3.41319C2.91804 14.6794 2.49988 14.3108 2.47938 13.8597L2.06836 4.81641" stroke={props.color} strokeWidth="1.07368" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.00195 5.64062V12.6269" stroke={props.color} strokeWidth="0.412955" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.5332 5.64062L4.75734 12.6269" stroke={props.color} strokeWidth="0.412955" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.46437 5.64062L9.24023 12.6269" stroke={props.color} strokeWidth="0.412955" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.39648 3.07031H12.6034" stroke={props.color} strokeWidth="1.07368" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.75977 2.76257L4.99309 2.01391C5.11181 1.633 5.55843 1.32422 5.99062 1.32422H8.01167C8.44388 1.32422 8.89042 1.63278 9.0092 2.01391L9.24252 2.76257" stroke={props.color} strokeWidth="1.07368"/>
    </svg>
    
  );
};

export default Icon;
