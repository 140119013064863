import React from "react";

const Icon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 3.3335V12.6668" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.33398 8H12.6673" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Icon;
