import Cookies from 'js-cookie';
import actions from './actions';
import {DataService} from '../../config/dataService/dataService';
import { message } from 'antd';
import config from '../../config/config';
import { checkIsAdmin } from '../../utility/utility';
const { loginBegin, loginSuccess, loginErr, loginWihPhoneBegin, loginWihPhoneSuccess, loginWihPhoneErr,loginWihPhoneVerifyBegin, loginWihPhoneVerifySuccess, loginWihPhoneVerifyErr, forgotPasswordBegin, forgotPasswordSuccess, forgotPasswordErr, resentBegin, resentSuccess, resentErr, logoutBegin, logoutSuccess, logoutErr, inviteCheckBegin, inviteCheckSuccess, inviteCheckErr } = actions;

const login = (data) => {
  return async dispatch => {
    try {
      dispatch(loginBegin());
      let response = await DataService.post('login', data);

      console.log("response", response)
      if(await response.data.actionResult.toLowerCase() == "SUCCESS".toLowerCase()){
        console.log("comming else")
        if(checkIsAdmin(response.data.userRoles)){
          message.success({ content:  response.data.message, duration: 2 });
          await localStorage.setItem(config.storageKeys.techUser, window.btoa(JSON.stringify(response.data)));
          await localStorage.setItem(config.storageKeys.access_token, window.btoa(response.data.accessToken));
          await Cookies.set(config.storageKeys.techlogedIn, true);
          return dispatch(loginSuccess(response.data));
        }else{
          message.success({ content:  'you don\'t have permission to access', duration: 2 });
        }
       
      }else{
        console.log("comming else")
        message.error({ content:  "Invalid Email or Password", duration: 2 });
        return dispatch(loginErr("Invalid Email or Password"));
      }
      // setTimeout(() => {
      //   Cookies.set('logedIn', true);
      //   return dispatch(loginSuccess(true));
      // }, 1000);
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};


const loginWithPhone = (data) => {
  return async dispatch => {
    try {
      dispatch(loginWihPhoneBegin());
      let response = await DataService.post('user/loginwithphone', data);
      if(await response.data.success){
        message.success({ content:  response.data.message, duration: 2 });
        return dispatch(loginWihPhoneSuccess(response.data.data));
      }else{
        message.error({ content:  response.data.message, duration: 2 });
        return dispatch(loginWihPhoneErr(response.data.message));
      }
      // setTimeout(() => {
      //   Cookies.set('logedIn', true);
      //   return dispatch(loginSuccess(true));
      // }, 1000);
    } catch (err) {
      dispatch(loginWihPhoneErr(err));
    }
  };
};


const loginWithPhoneVerify = (data) => {
  return async dispatch => {
    try {
      dispatch(loginWihPhoneVerifyBegin());
      let response = await DataService.post('user/loginwithphoneverfiy', data);
      if(await response.data.success){
        await localStorage.setItem(config.storageKeys.techUser, window.btoa(JSON.stringify(response.data.data)));
        await localStorage.setItem(config.storageKeys.access_token, window.btoa(response.data.data.token));
        await Cookies.set(config.storageKeys.techlogedIn, true);
        message.success({ content:  response.data.message, duration: 2 });
        return dispatch(loginWihPhoneVerifySuccess(response.data.data));
      }else{
        message.error({ content:  response.data.message, duration: 2 });
        return dispatch(loginWihPhoneVerifyErr(response.data.message));
      }
      // setTimeout(() => {
      //   Cookies.set('logedIn', true);
      //   return dispatch(loginSuccess(true));
      // }, 1000);
    } catch (err) {
      dispatch(loginWihPhoneVerifyErr(err));
    }
  };
};

const forgotPassword = (data) => {
  return async dispatch => {
    try {
      dispatch(forgotPasswordBegin());
      let response = await DataService.post('user/forgot_password', data);
      if(await response.data.success){
        message.success({ content:  response.data.message, duration: 2 });
        return dispatch(forgotPasswordSuccess(response.data.user));
      }else{
        message.error({ content:  response.data.message, duration: 2 });
        return dispatch(forgotPasswordErr(response.data.message));
      }
      // setTimeout(() => {
      //   Cookies.set('logedIn', true);
      //   return dispatch(loginSuccess(true));
      // }, 1000);
    } catch (err) {
      dispatch(forgotPasswordErr(err));
    }
  };
};

const checkInvite = (data) => {
  return async dispatch => {
    try {
      dispatch(inviteCheckBegin());
      let response = await DataService.post('user/check/invite', data);
      // console.log('response.data', response.data);
      if(await response.data.success){
        // message.success({ content:  response.data.message, duration: 2 });
        return dispatch(inviteCheckSuccess(response.data.status));
      }else{
        // message.error({ content:  response.data.message, duration: 2 });
        return dispatch(inviteCheckErr(response.data.message));
      }
      // setTimeout(() => {
      //   Cookies.set('logedIn', true);
      //   return dispatch(loginSuccess(true));
      // }, 1000);
    } catch (err) {
      dispatch(inviteCheckErr(err));
    }
  };
};

const updateInvite = (data) => {
  return async dispatch => {
    try {
      dispatch(inviteCheckBegin());
      let response = await DataService.post('user/invite/update', data);
      if(await response.data.success){
        message.success({ content:  response.data.message, duration: 2 });
        return dispatch(inviteCheckSuccess(response.data.success));
      }else{
        message.error({ content:  response.data.message, duration: 2 });
        return dispatch(inviteCheckErr(response.data.message));
      }
      // setTimeout(() => {
      //   Cookies.set('logedIn', true);
      //   return dispatch(loginSuccess(true));
      // }, 1000);
    } catch (err) {
      dispatch(inviteCheckErr(err));
    }
  };
};




const resentPassword = (data) => {
  return async dispatch => {
    try {
      dispatch(resentBegin());
      let response = await DataService.post('user/email/otp', data);
      if(await response.data.success){
        message.success({ content:  response.data.message, duration: 2 });
        return dispatch(resentSuccess(response.data.user));
      }else{
        message.error({ content:  response.data.message, duration: 2 });
        return dispatch(resentErr(response.data.message));
      }
      // setTimeout(() => {
      //   Cookies.set('logedIn', true);
      //   return dispatch(loginSuccess(true));
      // }, 1000);
    } catch (err) {
      dispatch(forgotPasswordErr(err));
    }
  };
};


const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin());
      config.storageTypes.localStorage.map((storage)=>{
        localStorage.removeItem(storage);
      });
      // config.storageTypes.localStorage.map((storage)=>{
      //   localStorage.removeItem(storage);
      // });
      config.storageTypes.cookies.map((storage)=>{
        Cookies.remove(storage);
      });
      dispatch(logoutSuccess(true));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, loginWithPhone, checkInvite, updateInvite, loginWithPhoneVerify, forgotPassword, resentPassword, logOut };
