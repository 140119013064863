import themes from '../../theme';
const { colors } = themes;
const config = {
    filterList: [
        {
            key:'All',
            value:1,
            lable:'All Companies'
        },
        {
            key:'Leads',
            value:2,
            lable:'Leads'
        },
        {
            key:'Opportunities',
            value:3,
            lable:'Opportunities'
        },
        {
            key:'Clients',
            value:4,
            lable:'Clients'
        },
        {
            key:'Active',
            value:5,
            lable:'Active Companies'
        },
        {
            key:'Inactive',
            value:6,
            lable:'Inactive Companies'
        }
    ],
    columns:[
        {
            key:'name',
            lable:'Name'
        },
        {
            key:'designation',
            lable:'Designation'
        },
        {
            key:'company',
            lable:'Company'
        },
        {
            key:'email',
            lable:'Email'
        },
        {
            key:'phone',
            lable:'Phone'
        },
        {
            key:'pipeline stage',
            lable:'pipeline stage'
        }
    ],
    pipeLineStage:['Opportunity', 'Client', 'Lead'],
    pipeLineTagColor:{
        Opportunity:colors.danger,
        Client:colors.success,
        Lead:colors.primary,
    }
};
  
  export default config;