
export const baseColors = {
  
  primary: "#2D9CDB",
  secondary: "#F78B0C",
  tertiary: "#F78B0C",
  success: "#4CAF50",
  danger: "#FB8C00",
  warning: "#D23737",
  info: "#F5F749",
  white: "#FFFFFF",
  gray: "#4F4F4F",
  gray1: "#939090",
  gray2: "#C4C4C4",
  gray3: "#E5E5E5",
  gray4: "#EEEEEE",
  gray5: "#F5F5F5",
  gray6: "#F8F8F8",
  gray7: "#F7F7F7",
  black: "#1B1C1E",
  dark: "#303030",
};

export const additionalColors = {
  binance: "#F0B90B",
  overlay: "#000000",
  gold: "#FFC700",
  silver: "#B2B2B2",
  bronze: "#E7974D",
};

export const themeColors = {
  ...baseColors,
  ...additionalColors,
  background: "#FFFFFF",
  backgroundAlt: "#EEF3FF",
  hrader: "#F7F7F7",
  sidebarBackground: "#39425B",
  sidebarSubBackground: "#515E82",
  text: "#4F4F4F",
  textAlt: "#123E58",
  textDisabled: "#E5E5E5",
  textDark: "#303030",
  profileCard: "#F3F8FE",
  disabled: "#E5E5E5",
  placeholder: "#767676",
  gradients: {
    blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
    violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)",
    violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
    gold: "linear-gradient(270.99deg, #F2960D -10.7%, #F2C00D 109.15%)",
  },
};
