const actions = {
 
  MODULE_BEGIN: 'MODULE_BEGIN',
  MODULE_SUCCESS: 'MODULE_SUCCESS',
  MODULE_ERR: 'MODULE_ERR',

  DROPDOWN_BEGIN: 'DROPDOWN_BEGIN',
  DROPDOWN_SUCCESS: 'DROPDOWN_SUCCESS',
  DROPDOWN_ERR: 'DROPDOWN_ERR',



  moduleBegin: () => {
    return {
      type: actions.MODULE_BEGIN,
    };
  },

  moduleSuccess: data => {
    return {
      type: actions.MODULE_SUCCESS,
      data,
    };
  },

  moduleErr: err => {
    return {
      type: actions.MODULE_ERR,
      err,
    };
  },


  DropDownBegin: () => {
    return {
      type: actions.DROPDOWN_BEGIN,
    };
  },

  DropDownSuccess: data => {
    return {
      type: actions.DROPDOWN_SUCCESS,
      data,
    };
  },

  DropDownErr: err => {
    return {
      type: actions.DROPDOWN_ERR,
      err,
    };
  },
};

export default actions;
