import React from "react";

const Icon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <rect width="16" height="16" transform="matrix(-1 0 0 1 16 0)" fill="#EDEDED"/> */}
      <path d="M6 12L10 8L6 4" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Icon;
