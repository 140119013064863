import themes from '../../theme';
const { colors } = themes;
const config = {
    filterList: [
        {
            key:'All',
            value:1,
            lable:'All Leads'
        },
        {
            key:'ContactMode',
            value:2,
            lable:'Contact Made'
        },
        {
            key:'ResponseReceived',
            value:3,
            lable:'Response Received'
        },
        {
            key:'NeedsDefined ',
            value:4,
            lable:'Needs Defined '
        },
        {
            key:'ProposalMade',
            value:5,
            lable:'Proposal Made'
        },
        {
            key:'NegotiationsStarted',
            value:6,
            lable:'Negotiations Started'
        },
        {
            key:'SaleClosed',
            value:6,
            lable:'Sale Closed'
        },
        {
            key:'DealCancelled',
            value:6,
            lable:'Deal Cancelled'
        }
    ],
    columns:[
        {
            key:'name',
            lable:'Name'
        },
        {
            key:'designation',
            lable:'Designation'
        },
        {
            key:'company',
            lable:'Company'
        },
        {
            key:'email',
            lable:'Email'
        },
        {
            key:'phone',
            lable:'Phone'
        },
        {
            key:'pipeline stage',
            lable:'pipeline stage'
        }
    ],
    pipeLineTagColor:{
        'Contact Made':colors.primary,
        'Respond Received':colors.primary,
        'News Defined':colors.danger,
        'Proposal Made':colors.danger,
        'Negotiations Started':colors.danger,
        'Sales Closed':colors.success,
        'Deal Cancelled':colors.danger
    }
};
  
  export default config;