import React from "react";

const Icon = (props) => {
  return (
    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 6L0.535899 -3.01142e-07L7.4641 -9.06825e-07L4 6Z" fill={props.color} />
    </svg>
  );
};

export default Icon;
