
const config = {
    list: [
        {
            name:'Wapptechlogics',
            id:1,
        },
        {
            key:'Codesolution',
            id:2,
        }
    ]
};
  
  export default config;