import React from "react";

const Icon = (props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.25 8.75V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V8.75" stroke={props.color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.08398 4.66797L7.00065 1.7513L9.91732 4.66797" stroke={props.color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7 1.75V8.75" stroke={props.color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Icon;
