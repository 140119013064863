import React from 'react';
import { Typography } from 'antd';
import { Div, AuthMain, AuthLeft, AuthRight, TextContent } from './style';
import { Outlet } from 'react-router-dom';
const { Title, Text } = Typography;
const AuthLayout = () => {
  return (
    <Div>
      <AuthMain>
          <AuthLeft>
            <div className="auth-side-content">
              <img src={require('../assets/img/desktop.svg')} alt="" />
              <br />
              <br />
              <TextContent>
                <Title className="title">Benefits of SimpliTrain Account</Title>
                <Text className="text"><img src={require('../assets/img/circle-checked.svg')} alt="" /> Become Instructor & share your knowledge with the world</Text>
                <Text className="text"><img src={require('../assets/img/circle-checked.svg')} alt="" /> Amazing deals on trainings near your location</Text>
              </TextContent>
            </div>
          </AuthLeft>
          <AuthRight>
            <Outlet />
          </AuthRight>
      </AuthMain>
    </Div>
    
  );
};

export default AuthLayout;
