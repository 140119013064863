/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Div, LeftSidebar, RightContent } from './style';
import MenueItems from './MenueItems';
import themes from '../theme';
import TopMenu from './TopMenu';
import config from '../config/config';
import { redirection } from '../redux/pageRedirection/actionCreator';
import SettingsMenueItems from './SettingsMenueItems';
// import { setRolePermission } from '../redux/roleWithPrivileges/actionCreator';
import { getPermissions } from '../redux/users/actionCreator';
const { header, leftSideBar, leftSideBarClose } = config;
const { colors } = themes;
const { Content, Sider } = Layout;

const ThemeLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { pageRouting, userData, permissions } = useSelector(state => {
    return {
      pageRouting: state.page.data,
      userData: state.auth.data,
      permissions: state.permissions.data
    };
  });

  const onMenuToggle = () => {
    setCollapsed(!collapsed);
  }

  // const getRolePermissions = async (roleIds) => {
  //   let RoleData = await dispatch(getPermissions({ids:roleIds}));
  //   if(RoleData){
  //     if(RoleData.type === 'USER_SUCCESS'){
  //       dispatch(setRolePermission(RoleData.data));
  //       // setRolePermission([...RoleData.data]);
  //     }
  //   }
  // }

  // useEffect(() => {
  //   getRolePermissions(activeOrganisation.role_id);
  // }, [activeOrganisation]);

  useEffect(() => {
    if (pageRouting) {
      switch (pageRouting) {
        case 'employee':
          history('/hr/employees/add');
          dispatch(redirection(''));
          break;
        case 'user':
          history('/settings/newuser');
          dispatch(redirection(''));
          break;
        case 'role':
          history('/settings/newrole');
          dispatch(redirection(''));
          break;
        case 'company':
          history('/leadgeneration/companies/add');
          dispatch(redirection(''));
          break;
        case 'lead':
          history('/leadgeneration/leads/add');
          dispatch(redirection(''));
          break;
        default:
          break;
      }
      
    }
  }, [pageRouting]);


  return (
    <Div>
      <Layout style={{background:colors.white}}>
        <TopMenu toggleCollapsed={onMenuToggle} />
        <Layout style={{background:colors.white, paddingTop:header}}>
          <LeftSidebar>
            <Sider trigger={null} collapsible width={leftSideBar} collapsedWidth={leftSideBarClose} collapsed={collapsed}>
              {
                pathname.includes('/settings')?
                  <SettingsMenueItems toggleCollapsed={onMenuToggle} />
                :<MenueItems toggleCollapsed={onMenuToggle} />
              }
            </Sider>
          </LeftSidebar>
          <RightContent collapsed={collapsed.toString()}>
            <Content
              className="site-layout-background"
              style={{
                margin: 0,
                minHeight: 300,
                height:'100%',
                position: 'relative'
              }}
            >
              <Outlet />
            </Content>
          </RightContent>
        </Layout>
      </Layout>
    </Div>
  );
};
export default ThemeLayout;
