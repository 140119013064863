import React from "react";

const Icon = (props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.30039 0.898438H3.06039C1.86969 0.898438 0.900391 1.86774 0.900391 3.05844V6.29844C0.900391 7.48914 1.86969 8.45844 3.06039 8.45844H6.30039C7.49109 8.45844 8.46039 7.48914 8.46039 6.29844V3.05844C8.46039 1.86774 7.49109 0.898438 6.30039 0.898438ZM7.38039 6.29844C7.38039 6.89514 6.89709 7.37844 6.30039 7.37844H3.06039C2.46369 7.37844 1.98039 6.89514 1.98039 6.29844V3.05844C1.98039 2.46174 2.46369 1.97844 3.06039 1.97844H6.30039C6.89709 1.97844 7.38039 2.46174 7.38039 3.05844V6.29844ZM6.30039 9.53844H3.06039C1.86969 9.53844 0.900391 10.5077 0.900391 11.6984V14.9384C0.900391 16.1291 1.86969 17.0984 3.06039 17.0984H6.30039C7.49109 17.0984 8.46039 16.1291 8.46039 14.9384V11.6984C8.46039 10.5077 7.49109 9.53844 6.30039 9.53844ZM7.38039 14.9384C7.38039 15.5351 6.89709 16.0184 6.30039 16.0184H3.06039C2.46369 16.0184 1.98039 15.5351 1.98039 14.9384V11.6984C1.98039 11.1017 2.46369 10.6184 3.06039 10.6184H6.30039C6.89709 10.6184 7.38039 11.1017 7.38039 11.6984V14.9384ZM14.9404 0.898438H11.7004C10.5097 0.898438 9.54039 1.86774 9.54039 3.05844V6.29844C9.54039 7.48914 10.5097 8.45844 11.7004 8.45844H14.9404C16.1311 8.45844 17.1004 7.48914 17.1004 6.29844V3.05844C17.1004 1.86774 16.1311 0.898438 14.9404 0.898438ZM16.0204 6.29844C16.0204 6.89514 15.5371 7.37844 14.9404 7.37844H11.7004C11.1037 7.37844 10.6204 6.89514 10.6204 6.29844V3.05844C10.6204 2.46174 11.1037 1.97844 11.7004 1.97844H14.9404C15.5371 1.97844 16.0204 2.46174 16.0204 3.05844V6.29844ZM14.9404 9.53844H11.7004C10.5097 9.53844 9.54039 10.5077 9.54039 11.6984V14.9384C9.54039 16.1291 10.5097 17.0984 11.7004 17.0984H14.9404C16.1311 17.0984 17.1004 16.1291 17.1004 14.9384V11.6984C17.1004 10.5077 16.1311 9.53844 14.9404 9.53844ZM16.0204 14.9384C16.0204 15.5351 15.5371 16.0184 14.9404 16.0184H11.7004C11.1037 16.0184 10.6204 15.5351 10.6204 14.9384V11.6984C10.6204 11.1017 11.1037 10.6184 11.7004 10.6184H14.9404C15.5371 10.6184 16.0204 11.1017 16.0204 11.6984V14.9384Z" fill={props.color}/>
    </svg>
  );
};

export default Icon;
