/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */

import config from "../config/config";

const { common } = config;

const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

const getImageUrl = (path) =>{
  return process.env.REACT_APP_IMG_URL+path;
}

const checkIsSupperAdmin = (roles) => {
  const check = roles.filter(el=> el == common.roles['SUPPERADMIN']);
  if(check.length>0){
    return true;
  }else{
    return false;
  }
}

const checkIsAdmin = (roles) => {
  const check = roles.filter(el=> el == common.roles['ADMIN']);
  if(check.length>0){
    return true;
  }else{
    return false;
  }
}

const timer = (time) => {
  // let downloadTimer = setInterval(function(){
  //   if(timeleft <= 0){
  //     clearInterval(downloadTimer);
  //     return 'true';
  //     // document.getElementById("countdown").innerHTML = "Finished";
  //   } else {
  //     return timeleft;
  //     // document.getElementById("countdown").innerHTML = timeleft + " seconds remaining";
  //   }
  //   timeleft -= 1;
  // }, 1000);
  let seconds = time % 60;
  let minutes = Math.floor(time / 60);
  minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
  seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
  return minutes + ':' + seconds;
}

const ArrayFieldcount = (array, key) => {
  let total =0;
  array.map(a => {
    if(a[key] && Number(a[key]) > 0){
      total = Number(total) + Number(a[key]);
    }
  });
  return total;
}

const yearsListData = (minYear) => {
  let data = [];
  const nowYear = new Date().getUTCFullYear();
  for (let index = minYear; index <= nowYear; index++) {
    data.push(index);
    if(index === nowYear){
      return data;
    }
  }
};

const getYearRange = (year) => {
  let data = [];
  let CIndex = 0;
  const list = yearsListData(year);
  list.forEach((element, index) => {
    if(index === CIndex){
      if(index > 0){
        if(element+1 === new Date().getFullYear()){
          data.push(`${new Date().getFullYear()}-Onwards`);
        } else if(element+10 > new Date().getFullYear()){
          data.push(`${element+1}-${new Date().getFullYear()}`);
        }else{
          data.push(`${element+1}-${element+10}`);
        } 
      }else{
        data.push(`${element}-${element+10}`);
      }
      CIndex = CIndex+10;
    }
  });
  return data;
}

const showLogin = () => {
  return true;
};

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}

const getUniqueData = (data) => {
  var unique = data.filter(onlyUnique);
  return unique;
}

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

const setDashboardGraph = async (result) => {
  let xValues = [];
  let yValue1 = [];
  let recurringText = 0;
  let oneShotText = 0;
  let yValue2 = [];
  await Object.entries(result).forEach(async([key, value]) => {
    let recurring = 0;
    let oneShot = 0;
    let newValue = await value.reduce(function (r, a) {
      r[a.student_id] = r[a.student_id] || [];
      r[a.student_id].push(a);
      return r;
    }, Object.create(null));
    await Object.entries(newValue).forEach(([key1, value1]) => {
      if(value1.length>1){
        recurring = Number(recurring) + Number(value1.length);
      }else{
        oneShot = Number(oneShot) + Number(value1.length);
      }
    });
    xValues.push(key);
    yValue1.push(recurring);
    yValue2.push(oneShot);
    recurringText = Number(recurringText) + recurring;
    oneShotText = Number(oneShotText) + oneShot;
    
  });
  
  return {
    x:xValues,
    data1: yValue1,
    data2: yValue2
  }
}


const getNumberCards = () => {
  if(window.innerWidth >= 1200){
      return 4;
  }else if(window.innerWidth >= 992 && window.innerWidth < 1200){
      return 3;
  }else if(window.innerWidth >= 480 && window.innerWidth < 992){
      return 2;
  }else if(window.innerWidth < 480){
      return 1;
  }
}

const getItemCardsWidth = () => {
  if(window.innerWidth >= 1200){
      return 24;
  }else if(window.innerWidth >= 992 && window.innerWidth < 1200){
      return 32;
  }else if(window.innerWidth >= 480 && window.innerWidth < 992){
      return 48;
  }else if(window.innerWidth < 480){
      return 100;
  }
}

const getColSize = () => {
  if(window.innerWidth >= 1200){
      return 6;
  }else if(window.innerWidth >= 992 && window.innerWidth < 1200){
      return 8;
  }else if(window.innerWidth >= 480 && window.innerWidth < 992){
      return 12;
  }else if(window.innerWidth < 480){
      return 24;
  }
}

const getScheduledColSize = () => {
  if(window.innerWidth >= 1200){
      return 8;
  }else if(window.innerWidth >= 992 && window.innerWidth < 1200){
      return 12;
  }else if(window.innerWidth >= 600 && window.innerWidth < 992){
      return 12;
  }else if(window.innerWidth < 600){
      return 24;
  }
}

const getModuleId = (data, name) => {
  let Id = null;
  data.map((item)=>{
    if(item.name.toLowerCase().replace(/\s/g, '') == name.toLowerCase().replace(/\s/g, '')){
      Id = item.id;
    }else if(item.submmodule && item.submmodule.length > 0){
      const find = item.submmodule.find(el=> el.name.toLowerCase().replace(/\s/g, '') == name.toLowerCase().replace(/\s/g, ''));
      if(find){
        Id = find.id;
      }else{
        Id = null;
      }
    }else{
      Id = null;
    }
  });
  return Id;
}

const checkPermission = (per, moduleId, type, section) => {
  if(per && per.length>0){
    const data = per.find(el=> el.sub_module_id == moduleId);
    if(type == 'view'){
      if(section){
        return data.view_permission.includes(section);
      }else{
        return data.permission.includes(type);
      }
    }else if(type == 'more'){
      return data.more_permission.includes(section);
    }else{
      console.log(data.permission, type);
      return data.permission.includes(type);
    }
  }else{
    return true;
  }
  

}

export { ellipsis, showLogin, getImageUrl, ArrayFieldcount, getModuleId, getUniqueData, checkPermission, groupBy, checkIsAdmin, checkIsSupperAdmin, toTitleCase, getYearRange, timer, yearsListData, setDashboardGraph, getNumberCards, getItemCardsWidth, getColSize, getScheduledColSize };
