const actions = {
  REDIRECTION_BEGIN: 'REDIRECTION_BEGIN',
  REDIRECTION_SUCCESS: 'REDIRECTION_SUCCESS',
  REDIRECTION_ERR: 'REDIRECTION_ERR',

  redirectionBegin: () => {
    return {
      type: actions.REDIRECTION_BEGIN,
    };
  },

  redirectionSuccess: data => {
    return {
      type: actions.REDIRECTION_SUCCESS,
      data,
    };
  },

  redirectionErr: err => {
    return {
      type: actions.REDIRECTION_ERR,
      err,
    };
  },
};

export default actions;
