import React, { lazy, Suspense, useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Routes, Navigate, Route } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import 'antd/dist/antd.css';
import './App.css';
import store from './redux/store';
import config from './config/config';
import AuthLayout from './layout/AuthLayout';
import ThemeLayout from './layout/withAdminLayout';

const Login = lazy(() => import('./container/authentication/SignIn'));
const SignUp = lazy(() => import('./container/authentication/Signup'));
const ForgotPass = lazy(() => import('./container/authentication/ForgotPassword'));
const ResetPass = lazy(() => import('./container/authentication/ResetPassword'));
const InviteUser = lazy(() => import('./container/authentication/InviteUser'));
const Dashboard = lazy(() => import('./container/dashboard'));
const Forms = lazy(() => import('./container/forms'));
const Faqs = lazy(() => import('./container/faqs'));
const FaqForm = lazy(() => import('./container/faqs/faqForm'));
const Supports = lazy(() => import('./container/supports'));
const SupportForm = lazy(() => import('./container/supports/supportForm'));
const Settings = lazy(() => import('./container/settings'));
const Learners = lazy(() => import('./container/learners'));
const Instructors = lazy(() => import('./container/instructors'));
const VenueProvider = lazy(() => import('./container/venueProvider'));
const Venues = lazy(() => import('./container/venues'));
const Course = lazy(() => import('./container/course'));
const Batches = lazy(() => import('./container/batches'));
const UsersAndRoles = lazy(() => import('./container/settings/users-roles'));
const UserForm = lazy(() => import('./container/settings/users-roles/overview/userForm'));
const RoleForm = lazy(() => import('./container/settings/users-roles/overview/roleForm'));
const GlobalSetting = lazy(() => import('./container/settings/global/faqs/GlobalSettingForm'));
const { theme } = config;

const ProviderConfig = () => {
  const { topMenu, darkMode, isLoggedIn, permissions, modules } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
      permissions: state.permissions.data,
      modules: state.modules.data,
    };
  });

  // const [path, setPath] = useState(window.location.pathname);

  // useEffect(() => {
  //   console.log('window.location.pathname', window.location.pathname);
  //   let unmounted = false;
  //   if (!unmounted) {
  //     setPath(window.location.pathname);
  //   }
  //   // eslint-disable-next-line no-return-assign
  //   return () => (unmounted = true);
  // }, [setPath]); 

  const NotFound = () => {
    return <Navigate to="/" />;
  };

  return (
    <ConfigProvider direction={'ltr'}>
      <ThemeProvider theme={{ ...theme, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? 
            <Routes>
              <Route path="/" element={<AuthLayout />}>
                <Route index element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >  <Login />
                  </Suspense>
                } />
                <Route path="register" element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >   <SignUp />
                  </Suspense>
                } />
                <Route path="forgotPassword" element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >   <ForgotPass />
                  </Suspense>
                } />
                <Route path="resetPassword/:email" element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >   <ResetPass />
                  </Suspense>
                } />
                <Route path="invite/:userId/:mappingId" element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >   <InviteUser />
                  </Suspense>
                } />
              </Route>
              <Route path="*" element={<NotFound />} />
              
            </Routes> : <Routes>
              <Route path="/" element={<ThemeLayout />}>
                <Route index element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >  <Dashboard />
                  </Suspense>
                } />
                <Route path="dashboard" element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >  <Dashboard />
                  </Suspense>
                } />

                <Route key={'learners'} path={'learners'} >
                  <Route path={""} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Learners />
                    </Suspense>
                  } />
                  {/* <Route path={'add'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <PageForm />
                    </Suspense>
                  } /> */}
                  {/* <Route path={'edit/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <PageForm />
                    </Suspense>
                  } /> */}
                  <Route path={'view/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Learners />
                    </Suspense>
                  } />
                  
                </Route>
                <Route key={'instructors'} path={'instructors'} >
                  <Route path={""} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Instructors />
                    </Suspense>
                  } />
                  {/* <Route path={'add'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <PageForm />
                    </Suspense>
                  } /> */}
                  {/* <Route path={'edit/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <PageForm />
                    </Suspense>
                  } /> */}
                  <Route path={'view/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Instructors />
                    </Suspense>
                  } />
                  
                </Route>

                  {/* Venue Provider Route Start */}


                <Route key={'venue-provider'} path={'venue-provider'} >
                  <Route path={""} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <VenueProvider />
                    </Suspense>
                  } />
                  {/* <Route path={'add'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <PageForm />
                    </Suspense>
                  } /> */}
                  {/* <Route path={'edit/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <PageForm />
                    </Suspense>
                  } /> */}
                  <Route path={'view/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <VenueProvider />
                    </Suspense>
                  } />
                  
                </Route>

                {/* Venue Provider Route End */}


                {/* Venues Route Start */}


                <Route key={'venues'} path={'venues'} >
                  <Route path={""} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Venues />
                    </Suspense>
                  } />
                  {/* <Route path={'add'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <PageForm />
                    </Suspense>
                  } /> */}
                  {/* <Route path={'edit/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <PageForm />
                    </Suspense>
                  } /> */}
                  <Route path={'view/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Venues />
                    </Suspense>
                  } />
                  
                </Route>

                 {/* Venues Route End */}


                  {/* Course courses Start */}


                <Route key={'courses'} path={'courses'} >
                  <Route path={""} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Course />
                    </Suspense>
                  } />
                  {/* <Route path={'add'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <PageForm />
                    </Suspense>
                  } /> */}
                  {/* <Route path={'edit/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <PageForm />
                    </Suspense>
                  } /> */}
                  <Route path={'view/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Course />
                    </Suspense>
                  } />
                  
                </Route>

                 {/* courses Route End */}


                   {/* Batches Start */}


                <Route key={'batches'} path={'batches'} >
                  <Route path={""} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Batches />
                    </Suspense>
                  } />
                  {/* <Route path={'add'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <PageForm />
                    </Suspense>
                  } /> */}
                  {/* <Route path={'edit/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <PageForm />
                    </Suspense>
                  } /> */}
                  <Route path={'view/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Batches />
                    </Suspense>
                  } />
                  
                </Route>

                 {/* Batches Route End */}

                <Route key={'faqs'} path={'faqs'} >
                  <Route path={""} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Faqs />
                    </Suspense>
                  } />
                  <Route path={'add'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <FaqForm />
                    </Suspense>
                  } />
                  <Route path={'edit/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <FaqForm />
                    </Suspense>
                  } />
                  <Route path={'view/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Faqs />
                    </Suspense>
                  } />
                  
                </Route>
                <Route key={'supports'} path={'supports'} >
                  <Route path={""} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Supports />
                    </Suspense>
                  } />
                  <Route path={'add'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <SupportForm />
                    </Suspense>
                  } />
                  <Route path={'edit/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <SupportForm />
                    </Suspense>
                  } />
                  <Route path={'view/:id'} element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <Supports />
                    </Suspense>
                  } />
                  
                </Route>
              
                <Route path="forms" element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >  <Forms />
                  </Suspense>
                } />


                <Route path="global/setting" element={
                    <Suspense
                      fallback={
                        <div className="spin">
                          <Spin />
                        </div>
                      }
                    >  <GlobalSetting />
                    </Suspense>
                   } />


               <Route path="settings" element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >  <Settings />
                  </Suspense>
                } />
                <Route path="settings/roles" element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >  <UsersAndRoles />
                  </Suspense>
                } />
                <Route path="settings/users" element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >  <UsersAndRoles />
                  </Suspense>
                } />
                <Route path="settings/newuser" element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >  <UserForm />
                  </Suspense>
                } />
                <Route path="settings/edituser/:userId" element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >  <UserForm />
                  </Suspense>
                } />
                <Route path="settings/newrole" element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >  <RoleForm />
                  </Suspense>
                } />
                <Route path="settings/editrole/:roleId" element={
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >  <RoleForm />
                  </Suspense>
                } />

                  
                </Route>

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          }
          {/* {isLoggedIn && (
            <Navigate to="/dashboard" />
          )} */}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
