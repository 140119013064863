import actions from './actions';
import loginActions from '../authentication/actions';
import {DataService} from '../../config/dataService/dataService';
import { message } from 'antd';
import config from '../../config/config';
const {
  userBegin,
  userSuccess,
  userErr,

  addUserBegin,
  addUserSuccess,
  addUserErr

} = actions;
const { loginSuccess } = loginActions;


const getUsers = org => {
  return async dispatch => {
    try {
      dispatch(userBegin());
      let response = await DataService.get(`user/getuserbyorgId/${org.id}`);
      if(response.data.success){
        return dispatch(userSuccess(response.data.user));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(userErr(response.data.message));
      }
      
    } catch (err) { dispatch(userErr(err));
    }
  };
};

const getUser = id => {
  return async dispatch => {
    try {
      dispatch(userBegin());
      let response = await DataService.get(`userrolemapping/${id}`);
      if(response.data.success){
        return dispatch(userSuccess(response.data.UserRoleMapping));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(userErr(response.data.message));
      }
      
    } catch (err) { dispatch(userErr(err));
    }
  };
};


const addUser = data => {
  return async dispatch => {
    try {
      dispatch(addUserBegin());
      let response = await DataService.post(`user`, data);
      if(response.data.success){
        return dispatch(addUserSuccess(response.data.success));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addUserErr(response.data.message));
      }
      
    } catch (err) { dispatch(addUserErr(err));
    }
  };
};

const updateUser = (id, data) => {
  return async dispatch => {
    try {
      // const formData = new FormData();
      // Object.keys(data).forEach(element => {
      //   formData.append(element, data[element]);
      // });
      dispatch(addUserBegin());
      let response = await DataService.put(`user/profile/update/${id}`, data);
      if(response.data.success){
        {response.data.message?
          message.success({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addUserSuccess(response.data.success));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addUserErr(response.data.message));
      }
      
    } catch (err) { dispatch(addUserErr(err));
    }
  };
};

const updateUserProfile = (id, data) => {
  return async dispatch => {
    try {
      const formData = new FormData();
      // Object.keys(data).forEach(element => {
      //   formData.append(element, data[element]);
      // });
      formData.append('file', data['file']);
      dispatch(addUserBegin());
      let response = await DataService.put(`user/uploadimg/${id}`, formData);
      if(response.data.success){
        // console.log('response.data.user', response.data.user.image);
        const newData = {...data, image:response.data.user.image}
        await localStorage.setItem(config.storageKeys.techUser, window.btoa(JSON.stringify(newData)));
        return dispatch(addUserSuccess(newData));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addUserErr(response.data.message));
      }
      
    } catch (err) { dispatch(addUserErr(err));
    }
  };
};

const removeUser = data => {
  return async dispatch => {
    try {
      dispatch(addUserBegin());
      let response = await DataService.delete(`userrolemapping/${data}`);
      if(response.data.success){
        // {response.data.message?
        //   message.success({ content:  response.data.message, duration: 2 })
        // :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addUserSuccess(response.data.success));
      }else{
        response.data.message?message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })
        return dispatch(addUserErr(response.data.message));
      }
      
    } catch (err) { dispatch(addUserErr(err));
    }
  };
};

const getPermissions = data => {
  return async dispatch => {
    try {
      dispatch(userBegin());
      let response = await DataService.post(`roleprivileges/getAllByRoleIds`, data);
      if(response.data.success){
        // {response.data.message?
        //   message.success({ content:  response.data.message, duration: 2 })
        // :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(userSuccess(response.data.RolePrivileges));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(userErr(response.data.message));
      }
    } catch (err) { dispatch(userErr(err));
    }
  };
};

const activeUser = (id, data) => {
  return async dispatch => {
    try {
      dispatch(addUserBegin());
      let response = await DataService.put(`userrolemapping/${id}`, data);
      if(response.data.success){
        // {response.data.message?
        //   message.success({ content:  response.data.message, duration: 2 })
        // :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addUserSuccess(response.data.success));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addUserErr(response.data.message));
      }
    } catch (err) { dispatch(addUserErr(err));
    }
  };
}

const inviteAgain = (data) => {
  return async dispatch => {
    try {
      dispatch(addUserBegin());
      let response = await DataService.post(`user/invite/resent`, data);
      if(response.data.success){
        {response.data.message?
          message.success({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addUserSuccess(response.data.message));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addUserErr(response.data.message));
      }
    } catch (err) { dispatch(addUserErr(err));
    }
  };
}

const inactiveUser = (orgId, data) => {
  return async dispatch => {
    try {
      dispatch(addUserBegin());
      let response = await DataService.put(`organizations/${orgId}/status/update`, data);
      if(response.data.success){
        // {response.data.message?
        //   message.success({ content:  response.data.message, duration: 2 })
        // :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addUserSuccess(response.data.success));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addUserErr(response.data.message));
      }
      
    } catch (err) { dispatch(addUserErr(err));
    }
  };
};

const getMappingData = (data) => {
  return async dispatch => {
    try {
      dispatch(addUserBegin());
      let response = await DataService.post(`userrolemapping/mapping/getmappingdata`, data);
      // console.log(response.data);
      if(response.data.success){
        return dispatch(addUserSuccess(response.data.UserRoleMapping));
      }else{
        // {response.data.message?
        //   message.error({ content:  response.data.message, duration: 2 })
        // :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addUserErr(response.data.message));
      }
      
    } catch (err) { dispatch(addUserErr(err));
    }
  };
};





export { getUsers, addUser, getUser, getMappingData, removeUser, inviteAgain, updateUser, updateUserProfile, getPermissions, inactiveUser, activeUser };
