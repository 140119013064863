import config from '../../config/config';
import actions from './actions';

const {
  USER_BEGIN,
  USER_SUCCESS,
  USER_ERR,

  ADD_USER_BEGIN,
  ADD_USER_SUCCESS,
  ADD_USER_ERR,

} = actions;

const initialStateList = {
  data: [],
  loading: false,
  error: null,
};

const UserReducer = (state = initialStateList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USER_BEGIN:
      return {
        ...initialStateList,
        loading: true,
      };
    case USER_SUCCESS:
      return {
        ...initialStateList,
        data,
        loading: false,
      };
    case USER_ERR:
      return {
        ...initialStateList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const initialStateObj = {
  data: {},
  loading: false,
  error: null,
};

const AddUserReducer = (state = initialStateObj, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ADD_USER_BEGIN:
      return {
        ...initialStateObj,
        loading: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...initialStateObj,
        data,
        loading: false,
      };
    case ADD_USER_ERR:
      return {
        ...initialStateObj,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};


export { UserReducer, AddUserReducer };
