import React from "react";

const Icon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill={props.color} />
      <path d="M14.6668 6.5L8.25016 12.9167L5.3335 10" stroke={'#ffffff'} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Icon;
