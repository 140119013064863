import { combineReducers } from 'redux';
import themeUsersReducer from './themeUsers/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import RedirectionReducer from './pageRedirection/reducers';
import { ModuleReducer } from './common/reducers';
import { RoleReducer } from './roleWithPrivileges/reducers';
import { UserReducer } from './users/reducers';
import { addPermissionReducer } from './roleWithPrivileges/reducers';

const rootReducers = combineReducers({  
  themeUsers: themeUsersReducer,
  auth: authReducer,
  ChangeLayoutMode,
  page: RedirectionReducer,
  modules: ModuleReducer,
  roles: RoleReducer,
  users: UserReducer,
  permissions: addPermissionReducer
});

export default rootReducers;
