import config from '../../config/config';
import actions from './actions';

const {
  MODULE_BEGIN,
  MODULE_SUCCESS,
  MODULE_ERR,

  DROPDOWN_BEGIN,
  DROPDOWN_SUCCESS,
  DROPDOWN_ERR,

} = actions;

const LocalModules = localStorage.getItem(config.storageKeys.techModules);
const initialStateList = {
  data: LocalModules?JSON.parse(LocalModules):[],
  loading: false,
  error: null,
};

const ModuleReducer = (state = initialStateList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case MODULE_BEGIN:
      return {
        ...initialStateList,
        loading: true,
      };
    case MODULE_SUCCESS:
      return {
        ...initialStateList,
        data,
        loading: false,
      };
    case MODULE_ERR:
      return {
        ...initialStateList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const DropDownReducer = (state = initialStateList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DROPDOWN_BEGIN:
      return {
        ...initialStateList,
        loading: true,
      };
    case DROPDOWN_SUCCESS:
      return {
        ...initialStateList,
        data,
        loading: false,
      };
    case DROPDOWN_ERR:
      return {
        ...initialStateList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};


export { ModuleReducer, DropDownReducer };
