import themes from '../../theme';
const { colors } = themes;
const config = {
    filterList: [
        {
            key:'All',
            value:1,
            lable:'All Batches'
        },
    ],
    columns:[
        {
            key:'name',
            lable:'Name'
        },
        {
            key:'designation',
            lable:'Designation'
        },
        {
            key:'company',
            lable:'Company'
        },
        {
            key:'email',
            lable:'Email'
        },
        {
            key:'phone',
            lable:'Phone'
        },
        {
            key:'pipeline stage',
            lable:'pipeline stage'
        }
    ],
    pipeLineTagColor:{
        'Contact Made':colors.primary,
        'Respond Received':colors.primary,
        'News Defined':colors.danger,
        'Proposal Made':colors.danger,
        'Negotiations Started':colors.danger,
        'Sales Closed':colors.success,
        'Deal Cancelled':colors.danger
    }
};
  
  export default config;